<template>
  <!-- 商城-积分商城--订单详情 -->
  <div class="content-page">
    <b-card class="mb-3 my_card">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/myexchange' }">My Exchange</el-breadcrumb-item>
          <el-breadcrumb-item>Order Details</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </b-card>

    <!-- 待取货....自提 -->
    <b-card class="mb-3 ship_content" v-if="orderData.pickup_method==2">
      <div class="d-flex align-items-center" style="height: 200px;padding: 0 20px;">
        <div class="col-sm-2 col-4 v-center">
          <img class="w-100" :src="pickUrl[orderData.order_status]" alt="" />
        </div>
        <div class="pl-3 d-flex flex-column">
          <span style="font-size: 20px;color: #333;font-weight: 500;">{{{0:'Pending Acceptance',1:'Pending
            Pickup',2:'Pending
            Confirmation',3:'Completed',4:'Rejected'}[orderData.order_status]}}</span>
          <span v-if="orderData.order_status==4">Reasons for refusal:{{orderData.order_refused_reason}}</span>
        </div>
      </div>
      <div class="d-flex bot_content" v-if="![0,4].includes(orderData.order_status)">
        <span class="pr-5">Pickup code</span>
        <span>{{orderData.pickup_code}}</span>
      </div>
    </b-card>

    <!-- 待收货....已完成 -->
    <b-card class="mb-3 ship_content" v-else>
      <!-- 待收货 -->
      <div class="d-flex align-items-center" style="height: 200px;padding: 0 20px;" v-if="orderData.order_status==2">
        <div class="col-sm-2 col-4 v-center">
          <img class="w-100" src="@/assets/images/pointsOrder/receipt.png" alt="" />
        </div>
        <div class="pl-3 d-flex justify-content-between flex-grow-1">
          <span style="font-size: 20px;color: #333;font-weight: 500;">Pending Confirmation</span>
          <el-button type="primary" @click="pointsOrderOperate">Confirm receipt</el-button>
        </div>
      </div>
      <!-- 已完成 -->
      <div class="d-flex align-items-center" style="height: 200px;padding: 0 20px;" v-else>
        <div class="col-sm-2 col-4 v-center">
          <img class="w-100" :src="pickUrl[orderData.order_status]" alt="" />
        </div>
        <div class="pl-3 d-flex flex-column">
          <span style="font-size: 20px;color: #333;font-weight: 500;">{{{0:'Pending Acceptance',1:'Pending
            Shipment',2:'Pending
            Confirmation',3:'Completed',4:'Rejected'}[orderData.order_status]}}</span>
          <span v-if="orderData.order_status==4">Reasons for refusal:{{orderData.order_refused_reason}}</span>
          <span v-if="orderData.points_type==1">Your purchased stickers will be reflected in the chat</span>
        </div>
      </div>

      <section v-if="orderData.points_type!=1">
        <div class="d-flex bot_content_receipt flex-column" v-if="![0,1].includes(orderData.order_status)">
          <div class="d-flex mb-2">
            <span class="pr-5 names">Shipper</span>
            <span>{{orderData.order_status==4?'No-date':orderData.logistics_company}}</span>
          </div>
          <div class="d-flex">
            <span class="pr-5 names">Tracking number</span>
            <span>{{orderData.order_status==4?'No-date':orderData.tracking_number}}</span>
          </div>
        </div>
      </section>
    </b-card>

    <b-card class="mb-3 inform_content" v-if="orderData.points_type!=1">
      <h5>Receiving Information</h5>
      <div class="d-border"></div>
      <div class="d-flex detail_content">
        <div class="pr-3">
          <img class="avatar-10" src="../../../assets/images/icon/address.png" alt="" />
        </div>
        <div class="pl-1" style="color: #333">
          <p>{{orderData.consignee_name}}
            <span class="ml-5" style="color: #999"> {{orderData.phone_prefix}} {{orderData.consignee_phone}}</span>
          </p>
          <p class="mt-2">Address:{{orderData.address}}，{{orderData.country}}</p>
        </div>
      </div>
    </b-card>
    <b-card class="mb-3">
      <div class="mb-2">
        <!-- 商品详情 -->
        <div class="row pb-2">
          <div class="col-3 col-sm-2 pb-2">
            <div class="img_detail">
              <img class="cursor-pointer" :src="orderData.product_image" alt="" @click="toProductDetail" />
              <div class="sold_out" v-if="!orderData.is_exist_product||orderData.is_sold_out">Sold out</div>
            </div>
          </div>
          <div class="col-4 col-sm-4 pl-md-0 pl-sm-5 v-center">
            <h5 class="mb-sm-5 cursor-pointer" @click="toProductDetail">{{orderData.product_name}}</h5>
            <div class="d-flex flex-wrap" style="color: #666;">
              <div v-for="(v, i) in orderData.product_attr" :key="i" class="d-flex">
                <p>{{v.attr_name}} : {{v.attr_value}}</p>
                <span v-if="i!=orderData.product_attr.length-1">；</span>
              </div>
            </div>
          </div>
          <div class="col-2 col-sm-3 text-md-center pl-sm-5 v-center">
            <p class="m-0">{{orderData.points}} Points*{{orderData.quantity}}</p>
          </div>
          <div class="col-3 col-sm-3 text-md-right v-center pl-sm-5">
            <p class="m-0">{{orderData.points_amount}} Points</p>
          </div>
        </div>
        <div class="d-border"></div>
      </div>
      <div class="row">
        <div class="col-md-5 col-12 r-border mr-3">
          <div class="row">
            <div class="col">Order number:</div>
            <div class="col">{{orderData.order_sn}}</div>
          </div>
          <div class="row">
            <div class="col">Creation time:</div>
            <div class="col">{{orderData.add_time}}</div>
          </div>
        </div>
        <div class="col-md-5 col-12">
          <div class="row">
            <div class="col">Payment time:</div>
            <div class="col">{{orderData.payment_time}}</div>
          </div>
          <div class="row" v-if="orderData.order_status==4">
            <div class="col">Processing time:</div>
            <div class="col">{{orderData.end_time}}</div>
          </div>
          <div class="row" v-if="(orderData.order_status==2||orderData.order_status==3)&&orderData.pickup_method==1">
            <div class="col">Delivery time:</div>
            <div class="col">{{orderData.delivery_time}}</div>
          </div>
          <div class="row" v-if="orderData.order_status==3">
            <div class="col">Completion time:</div>
            <div class="col">{{orderData.complete_time}}</div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        orderData: {},
        pickUrl: {
          0: require('@/assets/images/pointsOrder/accept.png'),
          1: require('@/assets/images/pointsOrder/pick_up.png'),
          2: require('@/assets/images/pointsOrder/receipt.png'),
          3: require('@/assets/images/pointsOrder/complete.png'),
          4: require('@/assets/images/pointsOrder/reject.png'),
        }
      };
    },
    mounted() {
      this._pointsOrderDetails()
    },
    methods: {
      async _pointsOrderDetails() {
        let res = await this.$http.pointsOrderDetails({ order_id: this.$route.query.id })
        if (res.status == 200) {
          this.orderData = res.data
        }
      },

      toProductDetail() {
        if (this.orderData.is_exist_product == 1 && this.orderData.is_sold_out != 1) {
          this.$router.push(`/shopscoredetails?id=${this.orderData.product_id}`)
        } else {
          this.$message.warning('Product has been sold out !')
        }
      },

      // 积分订单确认操作
      async pointsOrderOperate(order_id) {
        let res = await this.$http.pointsOrderOperate({ order_id: this.$route.query.id })
        if (res.status == 200) {
          this._pointsOrderDetails();
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .card-body {
    padding: 20px;
  }

  .my_card {
    .card-body {
      padding: 0;
    }
  }

  .breadcrumb {
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    ::v-deep span.el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .ship_content {
    .card-body {
      padding: 0;
    }

    .bot_content {
      padding-left: 15px;
      height: 60px;
      line-height: 60px;
      border-top: 1px solid #eee;
    }

    .bot_content_receipt {
      padding: 20px 0 20px 15px;
      border-top: 1px solid #eee;

      .names {
        color: #333;
        width: 200px;
      }
    }
  }

  .inform_content {
    .card-body {
      padding: 0;
    }

    h5 {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
    }

    .detail_content {
      padding: 20px;

      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .btn2 {
    color: #50b5ff;
    background: #f5fbff;
    border: 1px solid #50b5ff;
    border-radius: 5px;
  }

  .v-center {
    margin: auto 0;
  }

  .d-border {
    width: 100%;
    height: 1px;
    background: #f1f1f1;
  }

  .r-border {
    border-right: 1px solid #f1f1f1;
  }

  .img_detail {
    position: relative;

    img {
      width: 120px;
      height: 120px;
    }

    .sold_out {
      position: absolute;
      bottom: 0;
      width: 120px;
      height: 30px;
      background: #ddd;
      text-align: center;
      line-height: 30px;
      color: #999;
      font-size: 14px;
    }
  }
</style>